import { yupResolver } from '@hookform/resolvers/yup'
import AOS from 'aos'
import axios from 'axios'
import { TitleH3 } from 'components/Common/Typography'
import Image from 'next/image'
import Router, { useRouter } from 'next/router'
import { ReactElement, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'

interface ModalProps {
  data?: any
  isOpen: boolean
  onClose: () => void
}

type FormValues = {
  yourName: string
  yourEmail: string
  phoneInput: string
  skypeID: string
  projectRequirements: string
}

const ContactModal = ({ onClose, isOpen, data }: ModalProps): ReactElement => {
  const schema = yup.object().shape({
    yourName: yup.string().max(80).trim().required('Your Name is required'),
    yourEmail: yup
      .string()
      .email('Invalid email format')
      .max(255)
      .required('Your Email is required'),
    projectRequirements: yup
      .string()
      .trim()
      .required('Your Project Requirements is required'),
    phoneInput: yup
      .string()
      .test('isValidPhoneNumber', 'Invalid phone number', (value) =>
        value ? isValidPhoneNumber(value) : true
      ),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const [SuccessMesg, setSuccessMesg] = useState('')
  const [ErrorMesg, setErrorMesg] = useState('')

  const router = useRouter()
  // console.log('router.query.path', router.pathname)
  const PageUrl = router.query.path
    ? router.query.path
    : router.pathname.split('/')[1]
  const [phoneCountryCode, setPhoneCountryCode] = useState()
  const [formCountryName, setFromCountryName] = useState()

  const onSubmit: SubmitHandler<FormValues> = (dataFetch) => {
    setSuccessMesg('')
    setErrorMesg('')
    const newComment = {
      name: dataFetch.yourName,
      email: dataFetch.yourEmail,
      skypeId: dataFetch.skypeID,
      contactNumber: dataFetch.phoneInput,
      pro_requirements: dataFetch.projectRequirements,
      pageName: `${PageUrl || 'Home page'} - 1 Week Risk Free Trial`,
      countryName: formCountryName,
    }

    fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/user/contactUs/sendInquiry`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(newComment),
    })
      .then((response) => response.json())
      .then(({ code }) => {
        if (code === 200) {
          setSuccessMesg('Thank you for your message. It has been sent.')

          setTimeout(() => {
            Router.push('/thank-you')
          }, 100)
        } else {
          setErrorMesg(data.message)
        }
      })
      .catch((error) => {
        setErrorMesg('Please try again after sometime')
      })
  }

  const fetchIpLocation = () => {
    ;(async () => {
      await axios.get(`https://ipapi.co/json/`).then((response) => {
        setPhoneCountryCode(response.data.country_code)
        setFromCountryName(response.data.country_name)
      })
    })()
  }
  useEffect(() => {
    fetchIpLocation()
    AOS.init()
  }, [])
  const [contactPopup, setContactPopup] = useState(false)

  return (
    // <></>
    // <Modal
    //   show={isOpen}
    //   onHide={onClose}
    //   className="form_modal contact_modal_wrap"
    //   centered
    // >
    //   <Row className="contact_modal_row">
    //     <Col lg={6}>
    //       <div className="modal_left_side_wrap">
    //         <div className="logo_left">
    //           <Image
    //             width={380}
    //             height={70}
    //             src="./assets/images/logo.svg"
    //             className="img-fluid"
    //             alt="Webclues Infotech"
    //           />
    //         </div>
    //         <TitleH3
    //           className="mb-2 text_white"
    //           text="We'd Love to Hear From You, Drop Us a Line"
    //         />
    //         <div className="links color-white">
    //           <a
    //             className="link"
    //             href="mailto:sales@webcluesinfotech.com"
    //             title="Mail"
    //           >
    //             <div className="icon">
    //               <img
    //                 src="assets/images/mail-icon.svg"
    //                 alt="📨"
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">sales@webcluesinfotech.com</div>
    //           </a>

    //           <a
    //             className="link"
    //             href="skype:webcluesinfotech?chat"
    //             title="Skype"
    //           >
    //             <div className="icon">
    //               <img
    //                 src="assets/images/skype-icon.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">webcluesinfotech</div>
    //           </a>
    //           <div className="link">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/phone-icon.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">For Sales:</div>
    //           </div>
    //           <a className="link" href="tel:+918141068282" title="Call">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/india-flag.svg"
    //                 alt="india-flag"
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">INDIA: +91-814-106-8282</div>
    //           </a>
    //           <a className="link" href="tel:+19783099910" title="Call">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/usa1.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">USA: +1-(978)-309-9910</div>
    //           </a>
    //           <a className="link" href="tel:+971502578857" title="Call">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/uae.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">UAE: +971-50-257-8857</div>
    //           </a>
    //           <a className="link" href="tel:+44-776-841-8141" title="Call">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/uk.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">UK: +44-776-841-8141</div>
    //           </a>
    //           <a className="link" href="tel:+61480193028" title="Call">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/aus.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">AUS: +61-480-193-028</div>
    //           </a>
    //           <a className="link" href="tel:+16479497499" title="Call">
    //             <div className="icon">
    //               <img
    //                 src="assets/images/canada.svg"
    //                 alt=""
    //                 className="decoration"
    //                 draggable="false"
    //                 loading="lazy"
    //               />
    //             </div>
    //             <div className="label">Canada: +1-647-949-7499</div>
    //           </a>
    //         </div>
    //       </div>
    //     </Col>
    //     <Col lg={6}>
    //       <Modal.Header closeButton className="border-0 pb-1" />
    //       <Modal.Body className="pt-0">
    //         <section className="contactform_new form_modal_contactform pb-0">
    //           <Container fluid>
    //             <Row className="justify-content-start">
    //               <Col xl={12}>
    //                 <div className="logo_right">
    //                   <Image
    //                     width={380}
    //                     height={70}
    //                     src="./assets/images/logo_blue.svg"
    //                     className="img-fluid "
    //                     alt="Webclues Infotech"
    //                   />
    //                 </div>

    //                 <TitleH3
    //                   className="mt-4 mt-lg-3 b-2 text_black"
    //                   text="Looking for the right partner for your next technology project?"
    //                 />
    //                 <SubText
    //                   className="text_black mt-2"
    //                   text="Get In touch with us today"
    //                 />
    //                 <div className="mt-4">
    //                   <form onSubmit={handleSubmit(onSubmit)}>
    //                     <div className="d-flex" />
    //                     <Row>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Form.Control
    //                             type="text"
    //                             placeholder="Your Name *"
    //                             className="bg-transparent"
    //                             {...register('yourName', {
    //                               required: true,
    //                               maxLength: 80,
    //                             })}
    //                           />
    //                           <p className="alert-error">
    //                             {errors.yourName && (
    //                               <span>{errors.yourName.message}</span>
    //                             )}
    //                           </p>
    //                         </Form.Group>
    //                       </Col>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Form.Control
    //                             type="text"
    //                             placeholder="Your Email *"
    //                             className="bg-transparent"
    //                             {...register('yourEmail', {
    //                               required: true,
    //                               pattern: /^\S+@\S+$/i,
    //                             })}
    //                           />
    //                           <p className="alert-error">
    //                             {errors.yourEmail && (
    //                               <span>{errors.yourEmail.message}</span>
    //                             )}
    //                           </p>
    //                         </Form.Group>
    //                       </Col>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Controller
    //                             name="phoneInput"
    //                             control={control}
    //                             render={({ field: { onChange, value } }) => (
    //                               <PhoneInput
    //                                 value={value}
    //                                 onChange={(e: any) => onChange(e)}
    //                                 defaultCountry={phoneCountryCode}
    //                                 id="phoneInput"
    //                                 className="form-control d-flex border-disable bg-transparent"
    //                                 placeholder="Contact Number"
    //                               />
    //                             )}
    //                           />
    //                           <p className="alert-error">
    //                             {errors.phoneInput && (
    //                               <span>{errors.phoneInput.message}</span>
    //                             )}
    //                           </p>
    //                         </Form.Group>
    //                       </Col>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Form.Control
    //                             type="text"
    //                             placeholder="Skype ID"
    //                             className="bg-transparent"
    //                             {...register('skypeID', {})}
    //                           />
    //                         </Form.Group>
    //                       </Col>
    //                       <Col xl={12} className="mb-2 pt-2">
    //                         <Form.Control
    //                           as="textarea"
    //                           className="py-0 border-disable bg-transparent"
    //                           placeholder="Project Requirements *"
    //                           {...register('projectRequirements', {
    //                             required: true,
    //                           })}
    //                           rows={4}
    //                         />
    //                         <p className="alert-error">
    //                           {errors.projectRequirements && (
    //                             <span>
    //                               {errors.projectRequirements.message}
    //                             </span>
    //                           )}
    //                         </p>
    //                       </Col>
    //                       <Col xl={12} className="mt-4 mt-xxl-5">
    //                         <Button className="btn-black" type="submit">
    //                           <span>Send</span> <span>Request</span>
    //                         </Button>
    //                       </Col>

    //                       <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-successfully">
    //                         {SuccessMesg}
    //                       </p>
    //                       <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-error">
    //                         {ErrorMesg}
    //                       </p>
    //                     </Row>
    //                   </form>
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Container>
    //         </section>
    //       </Modal.Body>
    //     </Col>
    //   </Row>
    // </Modal>

    // new modal
    // <Modal
    //   show={isOpen}
    //   onHide={onClose}
    //   className="form_modal contact_modal_wrap contact_modal_popup"
    //   centered
    // >
    //   <Row className="contact_modal_row1">
    //     <Col lg={6}>
    //       <div className="modal_left_side_wrap modal_left_side_wrap_banner">
    //         <div className="d-lg-block d-none h-100 relative modal-side-img d-img-border">
    //           <Image
    //             width={990}
    //             height={1000}
    //             src="./assets/images/pop-up-banner.png"
    //             className="w-100 h-auto "
    //             alt="Webclues Infotech"
    //           />
    //         </div>
    //         <div className="d-lg-none d-block h-100 relative modal-side-img">
    //           <Image
    //             width={500}
    //             height={500}
    //             src="./assets/images/pop-up-banner-responsive.png"
    //             className="w-full h-full "
    //             alt="Webclues Infotech"
    //           />
    //         </div>
    //       </div>
    //     </Col>
    //     <Col lg={6}>
    //       <Modal.Header closeButton className="border-0 pb-1" />
    //       <Modal.Body className="pt-0">
    //         <section className="contactform_new form_modal_contactform pb-0">
    //           <Container fluid>
    //             <Row className="justify-content-start">
    //               <Col xl={12}>
    //                 <div className="logo_right">
    //                   <Image
    //                     width={380}
    //                     height={70}
    //                     src="./assets/images/logo_blue.svg"
    //                     className="img-fluid "
    //                     alt="Webclues Infotech"
    //                   />
    //                 </div>

    //                 <TitleH3
    //                   className="mt-4 mt-lg-3 b-2 text_black"
    //                   text="Get in Touch"
    //                 />
    //                 <SubText
    //                   className="text_black mt-2"
    //                   text="With Us, To Grow Your Business"
    //                 />
    //                 <div className="mt-4">
    //                   <form onSubmit={handleSubmit(onSubmit)}>
    //                     <div className="d-flex" />
    //                     <Row>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Form.Control
    //                             type="text"
    //                             placeholder="Your Name *"
    //                             className="bg-transparent"
    //                             {...register('yourName', {
    //                               required: true,
    //                               maxLength: 80,
    //                             })}
    //                           />
    //                           <p className="alert-error">
    //                             {errors.yourName && (
    //                               <span>{errors.yourName.message}</span>
    //                             )}
    //                           </p>
    //                         </Form.Group>
    //                       </Col>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Form.Control
    //                             type="text"
    //                             placeholder="Your Email *"
    //                             className="bg-transparent"
    //                             {...register('yourEmail', {
    //                               required: true,
    //                               pattern: /^\S+@\S+$/i,
    //                             })}
    //                           />
    //                           <p className="alert-error">
    //                             {errors.yourEmail && (
    //                               <span>{errors.yourEmail.message}</span>
    //                             )}
    //                           </p>
    //                         </Form.Group>
    //                       </Col>
    //                       <Col xl={12} className="mb-2">
    //                         <Form.Group className="mb-2">
    //                           <Controller
    //                             name="phoneInput"
    //                             control={control}
    //                             render={({ field: { onChange, value } }) => (
    //                               <PhoneInput
    //                                 value={value}
    //                                 onChange={(e: any) => onChange(e)}
    //                                 defaultCountry={phoneCountryCode}
    //                                 id="phoneInput"
    //                                 className="form-control d-flex border-disable bg-transparent"
    //                                 placeholder="Contact Number"
    //                               />
    //                             )}
    //                           />
    //                           <p className="alert-error">
    //                             {errors.phoneInput && (
    //                               <span>{errors.phoneInput.message}</span>
    //                             )}
    //                           </p>
    //                         </Form.Group>
    //                       </Col>

    //                       <Col xl={12} className="mb-2 pt-2">
    //                         <Form.Control
    //                           as="textarea"
    //                           className="py-0 border-disable bg-transparent"
    //                           placeholder="Project Requirements *"
    //                           {...register('projectRequirements', {
    //                             required: true,
    //                           })}
    //                           rows={4}
    //                         />
    //                         <p className="alert-error">
    //                           {errors.projectRequirements && (
    //                             <span>
    //                               {errors.projectRequirements.message}
    //                             </span>
    //                           )}
    //                         </p>
    //                       </Col>
    //                       <Col xl={12} className="mt-4 mt-xxl-5">
    //                         <Button className="btn-black" type="submit">
    //                           <span>Send</span> <span>Request</span>
    //                         </Button>
    //                       </Col>

    //                       <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-successfully">
    //                         {SuccessMesg}
    //                       </p>
    //                       <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-error">
    //                         {ErrorMesg}
    //                       </p>
    //                     </Row>
    //                   </form>
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Container>
    //         </section>
    //       </Modal.Body>
    //     </Col>
    //   </Row>
    // </Modal>

    /* <Modal
      show={isOpen}
      onHide={onClose}
      className="form_modal contact_modal_wrap contact_modal_popup chritsmas_popup"
      centered
    >
      <Row className="contact_modal_row1">
        <Col lg={6} className="c-box-one">
          <div className="modal_left_side_wrap modal_left_side_wrap_banner modal_chrisamas">
            <div className="cap">
              <Image
                width={150}
                height={122}
                src="./assets/images/cap.png"
                className="w-100 h-auto "
                alt="Webclues Infotech"
              />
            </div>
            <div className="relative modal-side-img d-img-border c-img-border">
              <Image
                width={611}
                height={766}
                src="./assets/images/chritsmas.jpg"
                className="w-100 h-auto "
                alt="Webclues Infotech"
              />
            </div>
          </div>
        </Col>
        <Col lg={6} className="c-box-two">
          <Modal.Header closeButton className="border-0 pb-1" />
          <Modal.Body className="pt-0">
            <section className="contactform_new form_modal_contactform pb-0">
              <Container fluid>
                <Row className="justify-content-start">
                  <Col xl={12}>
                    <div className="logo_right">
                      <Image
                        width={380}
                        height={70}
                        src="./assets/images/logo_blue.svg"
                        className="img-fluid "
                        alt="Webclues Infotech"
                      />
                    </div>

                    <TitleH3
                      className="mt-4 mt-lg-3 b-2 text_black"
                      text="Get in Touch"
                    />
                    <SubText
                      className="text_black mt-2"
                      text="With Us, To Grow Your Business"
                    />
                    <div className="mt-4">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-flex" />
                        <Row>
                          <Col xl={12} className="mb-2">
                            <Form.Group className="mb-2">
                              <Form.Control
                                type="text"
                                placeholder="Your Name *"
                                className="bg-transparent"
                                {...register('yourName', {
                                  required: true,
                                  maxLength: 80,
                                })}
                              />
                              <p className="alert-error">
                                {errors.yourName && (
                                  <span>{errors.yourName.message}</span>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col xl={12} className="mb-2">
                            <Form.Group className="mb-2">
                              <Form.Control
                                type="text"
                                placeholder="Your Email *"
                                className="bg-transparent"
                                {...register('yourEmail', {
                                  required: true,
                                  pattern: /^\S+@\S+$/i,
                                })}
                              />
                              <p className="alert-error">
                                {errors.yourEmail && (
                                  <span>{errors.yourEmail.message}</span>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col xl={12} className="mb-2">
                            <Form.Group className="mb-2">
                              <Controller
                                name="phoneInput"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <PhoneInput
                                    value={value}
                                    onChange={(e: any) => onChange(e)}
                                    defaultCountry={phoneCountryCode}
                                    id="phoneInput"
                                    className="form-control d-flex border-disable bg-transparent"
                                    placeholder="Contact Number"
                                  />
                                )}
                              />
                              <p className="alert-error">
                                {errors.phoneInput && (
                                  <span>{errors.phoneInput.message}</span>
                                )}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col xl={12} className="mb-2 pt-2">
                            <Form.Control
                              as="textarea"
                              className="py-0 border-disable bg-transparent"
                              placeholder="Project Requirements *"
                              {...register('projectRequirements', {
                                required: true,
                              })}
                              rows={4}
                            />
                            <p className="alert-error">
                              {errors.projectRequirements && (
                                <span>
                                  {errors.projectRequirements.message}
                                </span>
                              )}
                            </p>
                          </Col>
                          <Col xl={12} className="mt-4 mt-xxl-5">
                            <Button className="btn-black" type="submit">
                              <span>Send</span> <span>Request</span>
                            </Button>
                          </Col>

                          <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-successfully">
                            {SuccessMesg}
                          </p>
                          <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-error">
                            {ErrorMesg}
                          </p>
                        </Row>
                      </form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </Modal.Body>
        </Col>
      </Row>
    </Modal> */

    // <Modal
    //   show={isOpen}
    //   onHide={onClose}
    //   className="form_modal oneWeek_trial_popup text-white"
    //   centered
    // >
    //   <Row className="flex-md-row-reverse flex-row">
    //     <Col md={7} lg={8}>
    //       <div className="modal_right">
    //         <button
    //           type="button"
    //           aria-label="Close"
    //           className="bg-transparent border-0 p-0 position-absolute modal_close"
    //           onClick={onClose}
    //         >
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 16 16"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M1.81282 1.81279L14.1872 14.1872M14.1872 1.81279L1.81282 14.1872"
    //               stroke="#ccc"
    //               strokeWidth="1"
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //           </svg>
    //         </button>
    //         <Modal.Body className="p-0 pt-4 pt-lg-5 pe-xl-2">
    //           <section className="contact form_new ps-4 ps-xl-0 ps-xl-0 pe-4 pb-4">
    //             <TitleH3
    //               className="mt-3 mt-lg-0 b-2 text-white"
    //               text="Your Vision. Our Expertise. Let's Start Today!"
    //             />
    //             <p className="text_para_large fw-400 text-white">
    //               Fill out the form below and a WebClues strategist will reach
    //               out to discuss your ideas.
    //             </p>
    //             <div className="mt-4">
    //               <form onSubmit={handleSubmit(onSubmit)}>
    //                 <div className="d-flex" />
    //                 <Row>
    //                   <Col xl={6} className="mb-2">
    //                     <Form.Group className="mb-2">
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Your Name *"
    //                         className="bg-transparent"
    //                         {...register('yourName', {
    //                           required: true,
    //                           maxLength: 80,
    //                         })}
    //                       />
    //                       <p className="alert-error">
    //                         {errors.yourName && (
    //                           <span>{errors.yourName.message}</span>
    //                         )}
    //                       </p>
    //                     </Form.Group>
    //                   </Col>
    //                   <Col xl={6} className="mb-2 ">
    //                     <Form.Group className="mb-2">
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Your Email *"
    //                         className="bg-transparent"
    //                         {...register('yourEmail', {
    //                           required: true,
    //                           pattern: /^\S+@\S+$/i,
    //                         })}
    //                       />
    //                       <p className="alert-error">
    //                         {errors.yourEmail && (
    //                           <span>{errors.yourEmail.message}</span>
    //                         )}
    //                       </p>
    //                     </Form.Group>
    //                   </Col>
    //                   <Col xl={7} className="mb-2">
    //                     <Form.Group className="mb-2">
    //                       <Controller
    //                         name="phoneInput"
    //                         control={control}
    //                         render={({ field: { onChange, value } }) => (
    //                           <PhoneInput
    //                             value={value}
    //                             onChange={(e: any) => onChange(e)}
    //                             defaultCountry={phoneCountryCode}
    //                             id="phoneInput"
    //                             className="form-control d-flex border-disable bg-transparent"
    //                             placeholder="Contact Number"
    //                           />
    //                         )}
    //                       />
    //                       <p className="alert-error">
    //                         {errors.phoneInput && (
    //                           <span>{errors.phoneInput.message}</span>
    //                         )}
    //                       </p>
    //                     </Form.Group>
    //                   </Col>
    //                   <Col xl={5} className="mb-2">
    //                     {' '}
    //                     &nbsp;{' '}
    //                   </Col>

    //                   <Col xl={12} className="mb-2 pt-2">
    //                     <Form.Control
    //                       as="textarea"
    //                       className="py-0 border-disable bg-transparent"
    //                       placeholder="Project Requirements *"
    //                       {...register('projectRequirements', {
    //                         required: true,
    //                       })}
    //                       rows={4}
    //                     />
    //                     <p className="alert-error">
    //                       {errors.projectRequirements && (
    //                         <span>{errors.projectRequirements.message}</span>
    //                       )}
    //                     </p>
    //                   </Col>
    //                   <Col xl={12} className="mt-4 d-flex justify-content-end">
    //                     <button
    //                       className="btn btn--primary hover--animation d-flex justify-content-center align-items-center py-3 px-4 h-auto lh-1"
    //                       type="submit"
    //                     >
    //                       <span className="h6 mb-0 fw-400 lh-1">SEND</span>
    //                     </button>
    //                   </Col>

    //                   <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-successfully">
    //                     {SuccessMesg}
    //                   </p>
    //                   <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-error">
    //                     {ErrorMesg}
    //                   </p>
    //                 </Row>
    //               </form>
    //             </div>
    //           </section>
    //         </Modal.Body>
    //       </div>
    //     </Col>
    //     <Col md={5} lg={4} className="pe-xl-3">
    //       <div className="modal_left d-flex flex-column h-100 me-xl-2 pt-4 mx-md-0 mt-1 mt-md-0">
    //         <div className="top_belt text-center mt-lg-4 px-3 py-3 mb-4 mt-4 mt-lg-0">
    //           At WebClues, we craft exceptional web & app experiences with
    //           exclusive benefits
    //         </div>
    //         <div className="orange_block d-flex justify-content-center px-3">
    //           <p className="px-3 px-lg-4 py-2 mb-0 w-100 text-center lh-sm">
    //             Experience the expertise of our developers with
    //           </p>
    //         </div>
    //         <div className="text_small text-white text-center mt-3 pt-2 lh-1">
    //           1 WEEK FREE
    //         </div>
    //         <div className="text_big text-center text-white">TRIAL</div>
    //         <p className="text_bottom px-4 py-2 mb-0 w-100 text-center mt-auto mb-3 mt-3 lh-sm">
    //           Fast-track your digital transformation journey with top talent
    //           from WebClues
    //         </p>
    //       </div>
    //     </Col>
    //   </Row>
    // </Modal>
    <Modal
      show={isOpen}
      onHide={onClose}
      className="black_friday_popup form_modal oneWeek_trial_popup text-white"
      centered
    >
      <Row className="flex-lg-row-reverse flex-row">
        <Col lg={7}>
          <div className="modal_right">
            <button
              type="button"
              aria-label="Close"
              className="bg-transparent border-0 p-0 position-absolute modal_close"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M1 1L21 21"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                <path
                  d="M21 1L1 21"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <Modal.Body className="modal-box">
              <section className="contact form_new  ps-xl-0 ps-xl-0 pb-1  pb-lg-5">
                <TitleH3
                  className="mt-3 mt-lg-0  text-black mb-0 title"
                  text="Your Vision. Our Expertise. Let's Start Today!"
                />
                <p className="text_para_large fw-400 text-black subtitle">
                  Fill out the form below and a WebClues strategist will reach
                  out to discuss your ideas.
                </p>
                <div className="mt-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex" />
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Form.Group className="mb-2">
                          <Form.Control
                            type="text"
                            placeholder="Your Name *"
                            className="bg-transparent text-black"
                            {...register('yourName', {
                              required: true,
                              maxLength: 80,
                            })}
                          />
                          <p className="alert-error">
                            {errors.yourName && (
                              <span>{errors.yourName.message}</span>
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col xl={6} className="mb-2 ">
                        <Form.Group className="mb-2">
                          <Form.Control
                            type="text"
                            placeholder="Your Email *"
                            className="bg-transparent text-black"
                            {...register('yourEmail', {
                              required: true,
                              pattern: /^\S+@\S+$/i,
                            })}
                          />
                          <p className="alert-error">
                            {errors.yourEmail && (
                              <span>{errors.yourEmail.message}</span>
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col xl={7} className="mb-2">
                        <Form.Group className="mb-2 custom-phone">
                          <Controller
                            name="phoneInput"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                value={value}
                                onChange={(e: any) => onChange(e)}
                                defaultCountry={phoneCountryCode}
                                id="phoneInput"
                                className="form-control d-flex gap-3 border-disable bg-transparent text-black"
                                placeholder="Contact Number"
                              />
                            )}
                          />
                          <p className="alert-error">
                            {errors.phoneInput && (
                              <span>{errors.phoneInput.message}</span>
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col xl={5} className="mb-2">
                        {' '}
                        &nbsp;{' '}
                      </Col>

                      <Col xl={12} className="mb-2 pt-2">
                        <Form.Control
                          as="textarea"
                          className="py-0 border-disable bg-transparent text-black"
                          placeholder="Project Requirements *"
                          {...register('projectRequirements', {
                            required: true,
                          })}
                          rows={4}
                        />
                        <p className="alert-error">
                          {errors.projectRequirements && (
                            <span>{errors.projectRequirements.message}</span>
                          )}
                        </p>
                      </Col>
                      <Col xl={12} className="mb-2 pt-2 info ">
                        <input
                          type="checkbox"
                          id="name"
                          name="name"
                          value="name"
                          className="des-checkbox"
                        />
                        <label htmlFor="name">
                          We respect your privacy and do not tolerate spam and
                          will never sell rent lease or give away your
                          information (name, address, email, etc) to any third
                          party. Nor will we send you unsolicited email
                        </label>
                      </Col>
                      <Col xl={12} className="mt-4 d-flex justify-content-end">
                        <button
                          className="btn btn--primary hover--animation d-flex justify-content-center align-items-center py-3 px-4 h-auto lh-1"
                          type="submit"
                        >
                          <span className="h6 mb-0 fw-400 lh-1 send-btn">
                            SEND
                          </span>
                        </button>
                      </Col>

                      <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-successfully">
                        {SuccessMesg}
                      </p>
                      <p className="text_para pt-2 pe-0 pe-xl-5 me-0 me-lg-5 alert-error">
                        {ErrorMesg}
                      </p>
                    </Row>
                  </form>
                </div>
              </section>
            </Modal.Body>
          </div>
        </Col>
        <Col lg={5} className="">
          <div className="modal_left d-flex flex-column h-100  pt-4 mx-md-0  mt-1 mt-md-0"></div>
        </Col>
      </Row>
    </Modal>
  )
}

export default ContactModal
